import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { END } from 'redux-saga';
import nextI18nextConfig from '~/../next-i18next.config';
import { wrapper } from '~/store';
import defaultPage, {
  defaultPageServerSideContext,
} from '~/../hocs/defaultPage';
import NotFound from '~/components/Errors/NotFound';
import ServerError from '~/components/Errors/ServerError';
import Layout from '~/containers/Layout';

@defaultPage()
class Error extends React.Component {
  render() {
    const { statusCode } = this.props;
    let errorContent = null;
    if (statusCode === 404) {
      errorContent = <NotFound />;
    } else if (statusCode === 500) {
      errorContent = <ServerError />;
    }

    return (
      <Layout {...this.props}>
        <div className="container text-center">{errorContent}</div>
      </Layout>
    );
  }
}

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (ctx) => {
    const i18nProps = await serverSideTranslations(
      ctx.locale || process.env.LANGUAGE,
      ['common', 'error'],
      nextI18nextConfig,
    );

    const {
      token,
      profile,
      accountType,
      isAuthenticated,
      currentLanguage,
      clientIp,
      api,
      sessionId,
      ssoToken,
      query,
      res,
      err,
    } = await defaultPageServerSideContext({
      ...ctx,
      store,
    });

    let statusCode = 500;
    if (err && err.code === 'PAGE_NOT_FOUND') {
      statusCode = 404;
      res.statusCode = 404;
    } else {
      if (res) {
        statusCode = res.statusCode;
      } else if (err) {
        statusCode = err.statusCode;
      }
    }

    // end the saga
    store.dispatch(END);
    await store.sagaTask.toPromise();
    ctx.res.statusCode = statusCode;

    return {
      props: {
        ...i18nProps,
        token,
        profile,
        accountType,
        isAuthenticated,
        currentLanguage,
        sessionId,
        ssoToken,
        query,
        statusCode,
      },
    };
  },
);

export default Error;
